<template>
  <div>
    <el-row>
      <el-button
        size="mini"
        @click="openQuickIntegrationAdd()"
        style="height: 33px !important"
        class="integration-add-widget"
      >
        <i class="el-icon-circle-plus" style="color: white"></i>
      </el-button>
      <dialog-component
        ref="dialogRef"
        :title="'Add New ' + this.parentData.label + `'s Data`"
        :visible="quickAddIntegrationDialogVisible"
        :width="getIsMobile ? '100%' : '30%'"
        @before-close="handleClose"
        :close-on-press-escape="false"
        v-loading="loading"
        element-loading-text="Adding Data..."
        :fullscreen="true"
        class="el-dialog el-dialog-fullscreen"
        :containerWidth="'40%'"
      >
        <div>
          <template>
            <el-form
              label-position="left"
              ref="integrationForm"
              :model="quickForm"
            >
              <!-- <el-form-item
                :label="fieldLabel"
                :prop="'selectedValue'"
                :rules="[
                  ...(isFieldRequired(fieldLabel)
                    ? [
                        {
                          required: true,
                          message: `${fieldLabel} is required`,
                          trigger: 'blur',
                        },
                      ]
                    : []),
                ]"
              >
                <el-select
                  v-model="selectedValue"
                  :placeholder="'Select ' + fieldLabel"
                  :style="{ ...getStyle, width: '60%' }"
                  clearable
                >
                  <el-option
                    v-for="(option, index) in requiredData"
                    :label="option.label"
                    :value="option.value"
                    :key="index"
                  >
                  </el-option>
                </el-select>
              </el-form-item> -->
              <el-form-item
                v-for="(field, index) in fieldsToUpdate"
                :key="index"
                :label="field.label"
                :prop="field.key"
                :rules="
                  field.isRequired
                    ? [
                        {
                          required: true,
                          message: `${field.label} is required`,
                        },
                      ]
                    : []
                "
              >
                <el-select
                  v-if="field.type === 'select'"
                  v-model="quickForm[field.key]"
                  :placeholder="'Select ' + field.label"
                  :clearable="true"
                  :style="{ ...getStyle, width: '60%' }"
                >
                  <el-option
                    v-for="(option, index) in requiredDataMap[field.key]"
                    :label="option.label"
                    :value="option.value"
                    :key="index"
                  />
                </el-select>
                <el-input
                  v-else-if="field.type === 'text'"
                  v-model="quickForm[field.key]"
                  :placeholder="field.label"
                  :clearable="true"
                  :style="{ ...getStyle, width: '60%' }"
                />
                <el-date-picker
                  v-else-if="field.type === 'date'"
                  v-model="quickForm[field.key]"
                  type="date"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  placeholder="Select Date"
                  :style="{ ...getStyle, width: '60%' }"
                />
              </el-form-item>
            </el-form>
          </template>
        </div>
        <span slot="footer" class="dialog-footer float-right">
          <el-button @click="handleClose">Cancel</el-button>
          <template>
            <el-button
              class="scaling-button"
              @click="AddNewIntegrationData"
              :loading="loading"
              >{{ "Add " }} {{ this.parentData.label }}</el-button
            >
          </template>
        </span>
      </dialog-component>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { postAPICall } from "@/helpers/httpHelper";
export default {
  props: [
    "currentEntity",
    "fromRepeatable",
    "parentForm",
    "parentFields",
    "parentData",
    "openNewAdd",
    "detailed_add",
    "actionButtonField",
    "selfTemplateId",
  ],
  components: {},
  data() {
    return {
      quickAddIntegrationDialogVisible: false,
      loading: false,
      requiredData: [],
      integrationRequiredField: {},
      requiredIntegrationFields: [],
      fieldLabel: "",
      integrationValue2: null,
      fieldsToUpdate: [],
      integrationSelections: {},
      selectedValue: null,
      requiredDataMap: [],
      quickForm: {},
    };
  },
  async mounted() {
    let limit = (this.pageSizes && this.pageSizes[0]) || 10;
    this.pageSize = limit;
    await this.fetchAllPredefinedIntegrations();
    await this.fetchIntegrations();
  },
  computed: {
    ...mapGetters("templatesData", [
      "getNewEntityData",
      "getPrimaryEntityDataStatus",
      "getPrimaryEntityDataErrors",
      "getEntityDataCreateByTemplateStatus",
      "getNewEntityCreateError",
    ]),
    ...mapGetters("navigationOpen", ["getIsMobile"]),
    paginationProps() {
      return {
        pageSizes: this.pageSizes || [10, 20, 50],
      };
    },
    getStyle() {
      return `height:${this.parentData?.height - 30}px;`;
    },
    getElementStyle() {
      let borderStyle = "";
      if (this.parentData.styles) {
        let type = this.parentData.styles.border_type
          ? this.parentData.styles.border_type
          : "solid";
        let size = this.parentData.styles.border_size
          ? this.data.styles.border_size + "px"
          : "0px";
        let color = this.parentData.styles.border_color
          ? this.parentData.styles.border_color
          : "";
        let font_size =
          this.parentData.styles &&
          this.parentData.styles.font &&
          this.parentData.styles.font.font_size
            ? this.parentData.styles.font.font_size
            : 14;
        let bold =
          this.parentData.styles &&
          this.parentData.styles.font &&
          (this.parentData.styles.font.style == 1 ||
            this.parentData.styles.font.style == 3)
            ? "bold"
            : "";
        let italic =
          this.parentData.styles &&
          this.parentData.styles.font &&
          (this.parentData.styles.font.style == 2 ||
            this.parentData.styles.font.style == 3)
            ? "italic"
            : "";

        borderStyle = "border:" + type + " " + size + " " + color;

        borderStyle += ";";
        borderStyle += this.parentData.styles.background
          ? `background: ${this.parentData.styles.background}`
          : "";
        borderStyle += ";";
        borderStyle += "font-size:" + font_size + "px;";
        if (bold) {
          borderStyle += "font-weight:" + bold + ";";
        }
        if (italic) {
          borderStyle += "font-style:" + italic + ";";
        }
        borderStyle += this.parentData.styles.label_color
          ? `color: ${this.parentData.styles.label_color}`
          : "";
        borderStyle += ";";
      }
      return borderStyle;
    },
  },
  methods: {
    isFieldRequired() {
      const selectedField = this.requiredIntegrationFields.find(
        (f) => f.label === this.fieldLabel
      );
      return selectedField ? selectedField.isRequired : false;
    },
    async openQuickIntegrationAdd() {
      this.quickAddIntegrationDialogVisible = true;
      let IntegrationField = this.parentFields?.find(
        (field) => field.integration_settings.selectedIntegration
      );
      this.connection =
        this.parentData.integration_settings.selectedIntegration;
      if (!this.connection) {
        this.connection =
          IntegrationField.integration_settings.selectedIntegration;
      }
      this.dependencies =
        this.parentData.integration_settings.templateDependencies;
      this.macthedApplication = this.predefinedIntegrations.find(
        (app) =>
          app._id == this.parentData.integration_settings.selectedApplication
      );
      this.macthedEvent = this.macthedApplication.events.find(
        (e) => e._id == this.parentData.integration_settings.selectedEvent
      );
      this.requiredIntegrationFields =
        this.macthedEvent?.settings?.requiredFields || [];
      this.fieldsToUpdate = [
        ...(this.macthedEvent?.settings?.fieldsRequiredToUpdate || []),
      ];
      this.updatedFieldKey =
        this.requiredIntegrationFields.find((field) => field.isUsedToUpdate)
          ?.key || null;
      this.fieldLabel =
        this.requiredIntegrationFields.find((field) => field.isUsedToUpdate)
          ?.label || null;
      if (
        this.requiredIntegrationFields.length === 0 ||
        !this.updatedFieldKey
      ) {
        this.$notify.warning({
          title: "Warning",
          message: "No fields are required to update for this integration.",
        });
        this.quickAddIntegrationDialogVisible = false;
        return;
      }
      this.fieldsWithRequiredAsset = this.fieldsToUpdate.filter(
        (field) => field.requiredAsset
      );
      for (const field of this.fieldsWithRequiredAsset) {
        let matchedParentField = this.parentFields?.find(
          (parentField) =>
            parentField.integration_settings?.selectedAsset ===
            field.requiredAsset
        );
        if (matchedParentField && matchedParentField.key) {
          // Set the selectedAsset value to quickForm
          const key = matchedParentField.key;
          const value = this.parentForm[key + "/name"];
          if (value !== undefined) {
            this.quickForm[key] = value;
          } else {
            console.warn(`Key "${key}" not found or value is undefined.`);
          }
          this.$set(this.quickForm, field.key, value);
        } else {
          await this.getrequiredData(field, field.requiredAsset);
        }
      }
      const requestBody = {
        selectedApplication: this.macthedApplication.key,
        selectedEvent: this.updatedFieldKey,
        selectedIntegration: this.connection,
        requiredFields: this.parentData?.integration_settings?.requiredFields
          ? this.parentData.integration_settings.requiredFields
          : {},
      };
      const apiResponse = await this.fetchEventData(requestBody);
      if (apiResponse?.status === true) {
        this.requiredData = apiResponse.data.map((detail) => {
          return {
            label: detail.name,
            value: detail.id,
            cal_value: detail.value,
          };
        });
      } else {
        this.requiredData = [];
      }
    },
    async getrequiredData(a, b) {
      const payload = {
        selectedApplication: this.macthedApplication.key,
        selectedEvent: b,
        selectedIntegration: this.connection,
        requiredFields: this.parentData?.integration_settings?.requiredFields
          ? this.parentData.integration_settings.requiredFields
          : {},
      };
      const fieldData = await this.fetchRequiredFieldData(payload);
      this.impData = fieldData.map((l) => {
        return {
          label: l.name,
          value: l.id,
          cal_value: l.value,
        };
      });
      this.$set(this.requiredDataMap, a.key, [...this.impData]);
    },
    handleClose() {
      this.quickAddIntegrationDialogVisible = false;
      this.selectedValue = null;
      this.quickForm = {}
    },
    async fetchIntegrations() {
      let params = {
        limit: 30,
        page: 1,
      };
      let integrationData = await postAPICall("GET", `integrations`, params);
      return integrationData;
    },
    async fetchAllPredefinedIntegrations() {
      const response = await postAPICall(
        "GET",
        "integrations/service/get-all-applications"
      );
      this.predefinedIntegrations = response?.data || [];
    },
    async fetchEventData(requestBody) {
      try {
        const response = await postAPICall(
          "POST",
          `integrations/service/get-event-data`,
          requestBody
        );
        const data = response?.data || {};
        return data;
      } catch (error) {
        return error;
      }
    },
    async updateRecord(updateddata) {
      try {
        let payload = {
          provider: this.macthedApplication.key,
          connectionId: this.connection,
          asset: this.macthedEvent?.settings?.AddRecord,
          parameters: updateddata,
          data: updateddata,
        };
        let response = await postAPICall(
          "POST",
          "integrations/service/update-record",
          payload
        );
        if (response.success) {
          this.$notify.success({
            title: "Success",
            message: "Record updated successfully",
          });
          this.quickAddIntegrationDialogVisible = false;
          this.selectedValue = null;
          this.fieldsToUpdate.forEach((field) => {
            this.parentForm[field.key] = null;
          });
        }
      } catch (err) {
        return err;
      }
    },
    async AddNewIntegrationData() {
      this.$refs.integrationForm.validate((valid) => {
        if (valid) {
          let updatedData = {};
          if (this.selectedValue !== null) {
            updatedData[this.updatedFieldKey] = this.selectedValue;
          }
          this.fieldsToUpdate.forEach((field) => {
            if (field.key) {
              updatedData[field.key] = this.parentForm[field.key];
            }
          });
          this.updateRecord(updatedData);
        } else {
          this.$notify.warning({
            title: "Warning",
            message: "Please fill all the required fields",
          });
        }
      });
    },
    async fetchRequiredFieldData(payload) {
      try {
        const provider = payload.selectedApplication;
        const requestBody = {
          provider,
          field: payload.selectedEvent,
          formData: payload.requiredFields,
        };
        const response = await postAPICall(
          "POST",
          `integrations/service/get-requiredFields-data/${payload.selectedIntegration}`,
          requestBody
        );
        const data = response?.data || {};
        return data;
      } catch (error) {
        return error;
      }
    },
  },
};
</script>
<style>
.el-dialog__footer {
  padding: 0px 20px 50px;
  text-align: right;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.integration-add-widget {
  background-color: slategray;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
}
</style>
